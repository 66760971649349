import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';

const windowGlobal = typeof window !== 'undefined' && window;

let scrollPosition = 0;
const processPage = () => {
  const [activeHead, setActiveHead] = useState(0);

  const image = useStaticQuery(graphql`
    query {
      processResearch: file(relativePath: { eq: "processResearch.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      processDefine: file(relativePath: { eq: "processDefine.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      processDesign: file(relativePath: { eq: "processDesign.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      processEngineer: file(relativePath: { eq: "processEngineer.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      processQa: file(relativePath: { eq: "processQa.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      processLaunch: file(relativePath: { eq: "processLaunch.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      processGrow: file(relativePath: { eq: "processGrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const scrollContentArray = [
    {
      id: 'research',
      title_id: 'research-link',
      sNo: '01',
      title: 'Research',
      description: 'Our product development process starts with research. We research about the competitors, target demographic, gather feedback and validate business assumptions.',
      image: <Img fluid={image.processResearch.childImageSharp.fluid} />,
    },
    {
      id: 'define',
      title_id: 'define-link',
      sNo: '02',
      title: ' Define',
      description: 'After research we decide on the features of the product, finalize the scope of work, draw out a road map, discuss and frame timelines for delivery.',
      image: <Img fluid={image.processDefine.childImageSharp.fluid} />,
    },
    {
      id: 'design',
      sNo: '03',
      title_id: 'design-link',
      title: 'Design',
      description: 'The UI and backend architecture of the product is designed based on the features finalized. The process involves designing the information architecture, user navigation and the UI components of the product. Backend architecture is also designed in this stage to ensure scalability and to define the interaction between various services.',
      image: <Img fluid={image.processDesign.childImageSharp.fluid} />,
    },
    {
      id: 'engineer',
      title_id: 'engineer-link',
      sNo: '04',
      title: 'Engineer',
      description: 'Our engineering team is handed over the design and architectural specs. They convert the designs to frontend and backend code and bring the product to life. We continuously review and deploy the apps for testing.',
      image: <Img fluid={image.processEngineer.childImageSharp.fluid} />,
    },
    {
      id: 'qa',
      title_id: 'qa-link',
      sNo: '05',
      title: 'QA',
      description: 'QA is a recurring process that we do throughout the lifecycle of the product. Every feature, every line of code undergoes a QA process to assure we deliver a quality product on time.',
      image: <Img fluid={image.processQa.childImageSharp.fluid} />,
    },
    {
      id: 'launch',
      title_id: 'launch-link',
      sNo: '06',
      title: 'Launch',
      description: 'After several rounds of testing, we launch the final product by deploying services and apps to respective app stores and servers.',
      image: <Img fluid={image.processLaunch.childImageSharp.fluid} />,
    },
    {
      id: 'grow',
      title_id: 'grow-link',
      sNo: '07',
      title: 'Grow',
      description: 'After delivery we continue our partnership by ongoing support and development of the product. We keep an eye on the user feedback, recommend new features, UX improvements, and assure that issues/bugs are resolved quickly.',
      image: <Img fluid={image.processGrow.childImageSharp.fluid} />,
    },
  ];

  const handleScroll = () => {
    scrollPosition = windowGlobal.scrollY;
    const define = document.getElementById('define');
    const design = document.getElementById('design');
    const engineer = document.getElementById('engineer');
    const qa = document.getElementById('qa');
    const launch = document.getElementById('launch');
    const grow = document.getElementById('grow');

    if (scrollPosition < define.offsetTop - 150) {
      setActiveHead(0);
    } else if (scrollPosition >= define.offsetTop + 150 && scrollPosition < design.offsetTop + 100) {
      setActiveHead(1);
    } else if (scrollPosition >= design.offsetTop + 100 && scrollPosition < engineer.offsetTop + 50) {
      setActiveHead(2);
    } else if (scrollPosition >= engineer.offsetTop + 50 && scrollPosition < qa.offsetTop - 100) {
      setActiveHead(3);
    } else if (scrollPosition >= qa.offsetTop - 20 && scrollPosition < launch.offsetTop - 100) {
      setActiveHead(4);
    } else if (scrollPosition >= launch.offsetTop - 100 && scrollPosition < grow.offsetTop - 150) {
      setActiveHead(5);
    } else if (scrollPosition >= grow.offsetTop - 150) {
      setActiveHead(6);
    }
  };

  useEffect(() => {
    windowGlobal.addEventListener('scroll', handleScroll);
    return () => windowGlobal.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout pageInfo={{ pageName: 'process' }} title="Our Process" description="We follow a solid product development process which we have tried, tested, borrowed and improved over several years of work.">
      <SEO title="Process" />
      <Row noGutters className="mx-0 p-0">
        <Col xs={12} md={3} className="sticky-content d-none d-md-block">
          {scrollContentArray.map((item, index) => (
            <div className="py-3 px-1 d-none d-md-flex justify-content-end align-items-center" style={{ position: 'relative', backgroundColor: '#fff' }}>
              <div className="px-3">
                <h5 className={activeHead === index ? 'row-opacity-active font-weight-bold' : 'row-opacity'}>{item.title}</h5>
              </div>
              <div id={item.title_id} className={`border border-left-0 border-right-0 border-bottom-0 border-light line ${activeHead === index ? 'line-active' : ''}`} />
            </div>
          ))}
        </Col>
        <Col xs={12} md={9}>
          <div className="scroll-wrapper w-100">
            {scrollContentArray.map((item, index) => (
              <Row key={item.id} className={`m-0 scroll-content-row ${activeHead === index ? 'row-opacity-active' : 'row-opacity'}`}>
                <Col xs={2} md={2} id={item.id} lg={2}>
                  <h1 style={{ color: '#FFFFFF', fontSize: '3rem' }}>{item.sNo}</h1>
                </Col>
                <Col xs={10} md={10} lg={6}>
                  <div style={{ width: 400, maxWidth: '100%' }}>
                    <h1 style={{ color: '#FFFFFF', position: 'relative' }}>
                      {item.title}
                      <span className="process-background-title d-none d-md-block">{item.title}</span>
                    </h1>
                    <p className="pb-3" style={{ color: '#FFFFFF' }}>
                      {item.description}
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={4} className={activeHead === index ? 'process-image-opacity-active' : 'process-image-opacity'}>
                  {item.image}
                </Col>
              </Row>
            ))}
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

// const LoadableApproachPage = loadable(() => ApproachPage);

export default processPage;
